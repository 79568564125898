body {
  min-height: 100vh;
  background-color: #dedede;
  /* background-color: rgba(0, 0, 0, 0); */
}
p {
  color: black;
}

@media (environment-blending: additive) {
  body {
    background-color: black;
  }
}
